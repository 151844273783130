.coaching-corner-option {
  width: auto;
  text-align: center;
  color: #fff;
  justify-content: end;
  align-self: flex-end;
  padding: 2px;
  font-family: equipMedium;
  font-size: 16px;
  bottom: 0;
  background-color: #a4b3c3 !important;
  border-bottom: 0 solid #a4b3c3 !important;
}

.coaching-role-dropdown {
  width: auto;
  justify-content: end;
  align-self: flex-end;
  padding: 10px;
}

@media (width <= 975px) {
  .coaching-role-dropdown {
    display: none;
  }
}

.coaching-corner-option .ant-menu-submenu, .ant-menu-submenu-open, .ant-menu-submenu-title, .ant-menu-submenu-title:hover, .ant-menu-submenu-active, .ant-menu-submenu-horizontal, .ant-menu-item-selected, .ant-menu-overflow-item {
  color: #fff !important;
}

.coaching-options-submenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #1890ff !important;
}

.coaching-corner-icon.anticon {
  align-self: flex-end;
  font-size: 24px;
}

.coaching-corner-form {
  background-color: #f6f8fa;
  padding: 20px;
}

.coaching-rte-toolbar {
  display: none;
}

.coaching-field {
  margin: 5px 0;
  padding-left: 5px;
  padding-right: 5px;
}

.coaching-field .ant-form-item {
  margin: 0;
}

.coaching-notes {
  padding-left: 5px;
  padding-right: 45px;
}

.coaching-formid {
  margin-top: 15px;
}

.coaching-input {
  height: 42.5px;
  color: #424242 !important;
  border: 1px solid #ced4da !important;
  border-radius: .25rem !important;
  font-size: 18px !important;
}

.coaching-attachment input {
  width: 100%;
  background-color: #fff;
  align-self: center;
  margin: auto;
  color: #424242 !important;
  border: 1px solid #ced4da !important;
  border-radius: .25rem !important;
}

.coaching-disclaimer {
  margin-top: 3px;
  font-size: 11px;
  color: #424242 !important;
}

.coaching-disclaimer span {
  border-left: 1px solid #b5b5b5;
  margin-left: 15px;
  padding-left: 15px;
}

.coaching-date {
  width: 100%;
}

.coaching-corner-form .form-group {
  padding-right: 10px;
}

.coaching-corner-subtitle {
  color: #69bce3;
  padding: 5px;
  font-family: equipMedium;
  font-size: 16px;
}

.notes-spacer {
  margin-top: 10px;
}

.form-column.ant-col {
  border-right: 3px solid #fff;
  flex-wrap: wrap;
  margin-bottom: 50px;
  display: flex;
}

.icon-column.ant-col {
  flex-wrap: wrap;
  margin: auto;
  display: flex;
}

.coaching-details {
  text-align: center;
}

.coaching-details-option {
  margin: 5px auto 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.coaching-details .select-search__group-header {
  color: #1998d5;
  padding: 2px;
}

.coaching-corner-form .select-search__option:hover {
  color: #1998d5;
  background-color: #f6f8fa;
  font-family: equipMedium;
}

.coaching-corner-form ul {
  margin-left: 0;
}

.coaching-form-submit {
  margin-top: 110px;
}

.coaching-corner-button.ant-btn {
  width: 100%;
  height: 48px;
  color: #fff;
  text-align: center;
  background-color: #1998d5;
  border-radius: 25px;
  font-family: equipmedium;
  font-size: 16px;
  display: block;
}

.coaching-corner-error {
  text-align: left;
  color: red;
  padding: 1%;
  font-family: equiplight;
  font-size: 14px;
}

.coaching-corner-warning {
  color: red;
  padding: 1%;
}

.coaching-dashboard-wrapper {
  height: 100vh;
  background-color: #f9f9f9;
  padding: 1% 2%;
}

.coaching-description {
  display: list-item;
  position: relative;
}

.coaching-dashboard-table td, th {
  font-size: 14px !important;
}

.coaching-dashboard-table table {
  border: 1px solid #f0f0f0;
}

.coaching-dashboard-column {
  min-width: 125px;
}

.coaching-dashboard-column-large {
  min-width: 200px;
}

.coaching-dashboard-date {
  width: 250px;
  height: 42.5px;
  border-radius: 5px !important;
  margin-left: 10px !important;
}

.coaching-dashboard-button {
  border-radius: 5px !important;
}

.csv-export {
  margin-right: 10px;
}

a.csv-export {
  text-decoration: none !important;
}

.coaching-row {
  margin-bottom: 20px;
  display: flex;
}

.coaching-column {
  width: 50%;
  padding-left: 10px;
  padding-right: 10px;
}

.coaching-label {
  color: #00000073;
  margin-right: 10px;
  font-size: 14px;
}

.coaching-value {
  font-size: 14px;
}

.coaching-value a {
  color: #0089c7 !important;
}

.coaching-attachment-button {
  bottom: 25px;
  right: 75px;
  position: absolute !important;
}

.coaching-test-dropdown .select-search .select-search__select {
  z-index: 250 !important;
}

.ant-btn.coaching-reprocess-button {
  width: 150px;
  position: absolute;
  top: 12.5px;
  right: 225px;
}

.ant-btn.coaching-deliver-button {
  width: 150px;
  position: absolute;
  top: 12.5px;
  right: 50px;
}

.ant-btn.coaching-download-button {
  width: 235px;
  position: absolute;
  top: 12.5px;
  right: 50px;
}

.ant-btn.coaching-delete-button {
  width: 150px;
  background-color: #52c41a;
  border: #52c41a;
  position: absolute;
  top: 12.5px;
  right: 50px;
}

.ant-btn.coaching-approve-button {
  width: 150px;
  background-color: #52c41a;
  border: #52c41a;
  position: absolute;
  top: 12.5px;
  right: 400px;
}

.ant-btn.coaching-approve-button:hover {
  background-color: #73d13d;
  border: #73d13d;
}

.ant-btn.coaching-approve-button:active {
  background-color: #389e0d;
  border: #389e0d;
}

.ant-btn.coaching-approve-button:disabled {
  background-color: #f6ffed;
  border: #b7eb8f;
}

.ant-btn.coaching-approve-button:focus {
  background-color: #73d13d;
  border: #73d13d;
}

.additionalNotesPopover {
  max-width: 750px;
}

.additionalNotesWrapper .ant-popover-inner {
  border: 2px solid red;
}

.additionalNotesWrapper .ant-popover-title {
  font-weight: 700;
}

.additionalNotesPopoverButton {
  margin-left: 175px;
}

.coaching-indicator {
  width: 110px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-family: equipmedium;
  font-size: 13px;
  display: flex;
}

.coaching-indicator .anticon {
  padding-right: 4px;
}

.rework {
  color: red;
  border: 1px solid red;
}

.pending {
  color: orange;
  border: 1px solid orange;
}

.ready {
  color: #1998d5;
  border: 1px solid #1998d5;
}

.delivered {
  color: #40a142;
  border: 1px solid #40a142;
}

.deleted {
  color: #424242bf;
  border: 1px solid #424242bf;
}

.ant-modal.coaching-modal {
  width: 65% !important;
}

.coaching-preview {
  max-height: 250px;
  background-color: #f6f8fa;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 2%;
  overflow-y: scroll;
}

.coaching-subheader {
  margin-top: 25px;
  font-family: equipmedium;
}

.body {
  background-color: #fff;
  margin-top: 0;
}

.text-editor {
  height: 250px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
  overflow-y: scroll;
}

.text-editor .ProseMirror {
  height: 248px;
  padding: 10px;
  overflow: scroll;
}

.text-editor:active, .text-editor:focus, .text-editor:focus-within, .text-editor:target {
  height: 250px;
  overflow-y: scroll;
}

.csqa-platform-header {
  height: 120px;
  background-color: #a4b3c3;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.csqa-platform-wrapper {
  margin-bottom: 35%;
}

.csqa-platform-body .form-label {
  margin-top: 20px;
  font-size: 16px;
}

.csqa-platform-body .form-check-label, .csqa-platform-body .accordion-button {
  font-size: 16px;
}

.csqa-icon {
  width: 90px;
  float: left;
  top: 10px;
  left: 20px;
}

.csqa-platform-type-wrapper {
  float: right;
  margin-right: 25px;
  display: flex;
}

.csqa-dashboard-type-wrapper {
  float: right;
  margin-right: 15px;
}

.csqa-platform-selector {
  margin-left: 10px;
}

.csqa-platform-selector .select-search__input {
  height: 42.5px;
  height: 42.5px;
  font-size: 14px;
}

.csqa-platform-selector .select-search__options {
  margin-left: 2px;
}

.csqa-platform-selector .select-search__value input {
  color: #a6a6a6;
}

.csqa-platform-selector span {
  color: #d2d2d2;
}

.csqa-description {
  display: list-item;
  position: relative;
}

.csqa-dashboard-date {
  width: 250px;
  height: 42.5px;
  border-radius: 5px !important;
  margin-left: 10px !important;
}

.csqa-dashboard-selector {
  height: 42.5px !important;
  border-radius: 5px !important;
}

.csqa-dashboard-date input {
  color: #d2d2d2;
}

.csqa-platform-type-dropdown .form-control {
  color: #fff;
  background-color: #f1670d;
  border-color: #f1670d;
  border-radius: 5px;
}

.csqa-platform-type-dropdown .form-control:disabled {
  color: #fff;
  background-color: #818181;
  border-color: #818181;
  border-radius: 5px;
  animation: none !important;
}

.csqa-platform-category-dropdown .form-control {
  color: #fff;
  background-color: #69bce3;
  border-color: #69bce3;
  border-radius: 5px;
}

.csqa-platform-category-dropdown .form-control:disabled {
  color: #fff;
  background-color: #818181;
  border-color: #818181;
  border-radius: 5px;
  animation: none !important;
}

.vertical-divider {
  float: left;
  height: 55px;
  width: 1px;
  background-color: #fff;
  margin-left: 15px;
  margin-right: 15px;
}

.csqa-platform-header-wrapper {
  padding: 30px 30px 30px 50px;
}

.csqa-type-selector {
  float: right;
  border-radius: 5px;
  margin-top: 5px;
  margin-right: 50px;
}

.csqa-type-toggle:hover, .csqa-type-toggle:active {
  background-color: #9c4308;
  border-color: #9c4308;
}

.csqa-type-toggle {
  background-color: #f1670d;
  border-color: #f1670d;
  border-radius: 5px;
}

.show > .csqa-type-toggle, .show > .csqa-type-toggle:active {
  background-color: #9c4308 !important;
  border-color: #9c4308 !important;
  box-shadow: 0 0 0 .2rem #0003 !important;
}

.csqa-header-text {
  color: #fff;
  float: left;
  margin-top: -5px;
  font: 30px equipextralight;
}

.csqa-subheader-text {
  color: #fff;
  float: left;
  font: 18px equiplight;
}

.csqa-platform-body {
  padding: 2%;
}

.csqa-platform-form {
  padding-right: 10px;
}

.csqa-input-dropdown {
  color: #424242;
  padding-right: 10px;
}

.csqa-input-dropdown:hover {
  cursor: pointer;
}

.csqa-accordion {
  width: 100%;
  padding: 20px;
}

.csqa-accordion-header {
  color: #fff;
  background-color: #a4b3c3;
}

.csqa-accordion-header:hover {
  cursor: pointer;
  background-color: #1998d5;
}

.csqa-checkbox-element {
  margin-left: 15px;
  padding: 3px;
}

.csqa-checkbox-element:hover, .csqa-checkbox-element:hover .form-check-label {
  color: #1998d5;
  cursor: pointer;
}

.csqa-checkbox-description {
  margin-left: -5px;
  font: 16px equipextralight;
}

.csqa-platform-sidepanel {
  position: sticky;
  margin-top: 25px !important;
  padding-right: 50px !important;
}

.csqa-sidepanel-header {
  text-align: center;
  font: 24px equipextralight;
}

.csqa-platform-sidepanel-textelement {
  min-height: 350px;
  background-color: #fff;
  border: 1px solid #00000020;
  border-radius: 5px;
  margin: 0;
  padding: 20px;
}

.btn-primary.csqa-form-button {
  width: 272px;
  height: 48px;
  color: #fff;
  text-align: center;
  background-color: #1998d5;
  border-radius: 25px;
  margin-top: 15px;
  font-family: equipmedium;
  font-size: 16px;
  display: block;
}

.csqa-form-button:hover {
  background-color: #afd8f1;
  border: 0;
}

.select-search {
  width: 100%;
  box-sizing: border-box;
  font-family: equipextralight;
  position: relative;
}

.select-search__value {
  z-index: 1;
  position: relative;
}

.select-search__input {
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  color: #424242;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-family: equiplight;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  display: block;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search:not(.select-search--multiple) .select-search__select {
  z-index: 2;
  max-height: 360px;
  border-radius: 3px;
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  overflow: auto;
}

.select-search__select {
  background: #fff;
  box-shadow: 0 .0625rem .125rem #00000026;
  z-index: 20 !important;
}

.select-search__options {
  list-style: none;
}

.select-search__option {
  height: 36px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  background: #fff;
  border: none;
  outline: none;
  padding: 0 16px;
  font-family: equipextralight;
  font-size: 14px;
  display: block;
}

.errorResponse {
  color: red;
  padding: 1%;
  font-family: equiplight;
  font-size: 14px;
}

.Beta, .Gamma {
  height: 90px;
  width: 100%;
  color: #fff;
  text-align: center;
  background-color: #10628a;
  padding: 15px 15px 20px;
  font-family: equiplight;
  display: block;
}

.Prod {
  display: none;
}

.csqa-dashboard-table td, th {
  font-size: 14px !important;
}

.csqa-dashboard-table table {
  border: 1px solid #f0f0f0;
}

.review-header {
  color: #1998d5;
  text-align: center;
  font-size: 20px;
}

.review-subheader {
  color: #1998d5;
  text-align: left;
  font-size: 18px;
}

.review-label {
  color: #00000073;
  margin-right: 10px;
  font-size: 14px;
}

.review-value {
  font-size: 14px;
}

.review-details p {
  margin-bottom: 5px;
}

.review-row {
  margin-bottom: 20px;
  display: flex;
}

.review-column {
  flex: 50%;
  padding-left: 5px;
  padding-right: 5px;
}

.muted-text {
  color: #b4b4b4;
  margin-left: 10px;
}

.csqa-dashboard-button {
  border-radius: 5px !important;
}

.reset-button {
  width: 75px;
  background-color: #f6f8fa;
  border: 2px #1998d5;
  border-radius: 5px;
  position: absolute;
  top: 85px;
  right: 50px;
}

.reset-button:hover {
  color: #fff;
  background-color: #1998d5;
}

.open {
  display: block;
}

.closed {
  display: none;
}

.warning-text {
  color: red;
  font-size: 13px;
}

.hidden {
  opacity: 0;
  transition: visibility 2s, opacity .5s linear;
  display: none;
}

.visible {
  opacity: 1;
  display: block;
}

.csqa-rte-editor {
  min-height: 250px;
}

.csqa-rte-toolbar {
  z-index: 1 !important;
}

.csqa-header .has-focus, .csqa-platform-category-dropdown:hover {
  animation: none !important;
}

.csqa-header .is-active {
  z-index: 8;
  animation: .4s infinite alternate jump;
}

@keyframes jump {
  0% {
    transform: scale(1);
    box-shadow: 0 1px 2px #00000026;
  }

  100% {
    transform: scale(1.05);
    box-shadow: 0 4px 20px #0000001a;
  }
}

html, body {
  width: 100%;
  height: 100%;
}

input::-ms-clear, input::-ms-reveal {
  display: none;
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
}

[tabindex="-1"]:focus {
  outline: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

abbr[title], abbr[data-original-title] {
  -webkit-text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}

input[type="text"], input[type="password"], input[type="number"], textarea {
  -webkit-appearance: none;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1em;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5em;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1em;
}

dfn {
  font-style: italic;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}

figure {
  margin: 0 0 1em;
}

img {
  vertical-align: middle;
  border-style: none;
}

a, area, button, [role="button"], input:not([type="range"]), label, select, summary, textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
}

caption {
  text-align: left;
  caption-side: bottom;
  padding-top: .75em;
  padding-bottom: .3em;
}

input, button, select, optgroup, textarea {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="radio"], input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  width: 100%;
  max-width: 100%;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
  margin-bottom: .5em;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

mark {
  background-color: #feffe6;
  padding: .2em;
}

@font-face {
  font-family: equiplight;
  src: url("https://static.ring.com/fonts/Equip/Light/Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equip;
  src: url("https://static.ring.com/fonts/Equip/Regular/Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipmedium;
  src: url("https://static.ring.com/fonts/Equip/Medium/Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: equipextralight;
  src: url("https://static.ring.com/fonts/EquipW03Extra_Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.a55930b0.css.map */
