.csqa-platform-header {
    background-color: #A4B3C3;
    position: relative;
    height: 120px;
    padding-left: 10px;
    padding-right: 10px;
}

.csqa-platform-wrapper {
    margin-bottom: 35%;
}

.csqa-platform-body .form-label {
    margin-top: 20px;
    font-size: 16px;
}

.csqa-platform-body .form-check-label {
    font-size: 16px;
}

.csqa-platform-body .accordion-button {
    font-size: 16px;
}

.csqa-icon {
    width: 90px;
    top: 10px;
    left: 20px;
    float: left;
}

.csqa-platform-type-wrapper {
    float: right;
    display: flex;
    margin-right: 25px;
}

.csqa-dashboard-type-wrapper {
    float: right;
    margin-right: 15px;
}

.csqa-platform-selector {
    margin-left: 10px;
}

.csqa-platform-selector .select-search__input {
    font-size: 14px;
    height: 42.5px;
}

.csqa-platform-selector .select-search__input {
    font-size: 14px;
    height: 42.5px;
}

.csqa-platform-selector .select-search__options {
    margin-left: 2px;
}

.csqa-platform-selector .select-search__value input {
    color: #A6A6A6;
}

.csqa-platform-selector span {
    color: #D2D2D2;
}

.csqa-description {
    display: list-item;
    position: relative;
}

.csqa-dashboard-date {
    width: 250px;
    height: 42.5px;
    margin-left: 10px !important;
    border-radius: 5px !important;
}

.csqa-dashboard-selector {
    height: 42.5px !important;
    border-radius: 5px !important;
}

.csqa-dashboard-date input {
    color: #D2D2D2;
}
.csqa-platform-type-dropdown .form-control {
    color: #fff;
    background-color: #F1670D;
    border-color: #F1670D;
    border-radius: 5px;
}

.csqa-platform-type-dropdown .form-control:disabled {
    animation: none !important;
    color: #fff;
    background-color: #818181;
    border-color: #818181;
    border-radius: 5px;
}

.csqa-platform-category-dropdown .form-control {
    color: #fff;
    background-color: #69BCE3;
    border-color: #69BCE3;
    border-radius: 5px;
}

.csqa-platform-category-dropdown .form-control:disabled {
    animation: none !important;
    color: #fff;
    background-color: #818181;
    border-color: #818181;
    border-radius: 5px;
}

.vertical-divider {
    float: left;
    margin-left: 15px;
    margin-right: 15px;
    background-color: #fff;
    height: 55px;
    width: 1px;

}

.csqa-platform-header-wrapper {
    padding: 30px;
    padding-left: 50px;
}

.csqa-type-selector {
    float: right;
    margin-right: 50px;
    margin-top: 5px;
    border-radius: 5px;
}

.csqa-type-toggle:hover {
    background-color: #9C4308;
    border-color: #9C4308;
}

.csqa-type-toggle:active {
    background-color: #9C4308;
    border-color: #9C4308;
}

.csqa-type-toggle {
    background-color: #F1670D;
    border-color: #F1670D;
    border-radius: 5px;
}

.show > .csqa-type-toggle {
    background-color: #9C4308 !important;
    border-color: #9C4308 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,0,0, .2) !important;
}

.show > .csqa-type-toggle:active {
    background-color: #9C4308 !important;
    border-color: #9C4308 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,0,0, .2) !important;
}

.csqa-header-text {
    font: 30px equipextralight;
    color: #fff;
    float: left;
    margin-top: -5px;
}

.csqa-subheader-text {
    font: 18px equiplight;
    color: #fff;
    float: left;
}

.csqa-platform-body {
    padding: 2%;
}

.csqa-platform-form {
    padding-right: 10px;
}
.csqa-input-dropdown {
    padding-right: 10px;
    color: #424242;
}

.csqa-input-dropdown:hover {
    cursor: pointer;
}

.csqa-accordion {
    width: 100%;
    padding: 20px;
}

.csqa-accordion-header {
    background-color: #A4B3C3;
    color: #fff;
}

.csqa-accordion-header:hover {
    cursor: pointer;
    background-color: #1998d5;
}

.csqa-checkbox-element {
    padding: 3px;
    margin-left: 15px;
}

.csqa-checkbox-element:hover {
    color: #1998d5;
    cursor: pointer;
}

.csqa-checkbox-element:hover .form-check-label {
    color: #1998d5;
    cursor: pointer;
}

.csqa-checkbox-description {
    font: 16px equipextralight;
    margin-left: -5px;
}

.csqa-platform-sidepanel {
    padding-right: 50px !important;
    margin-top: 25px !important;
    position: sticky;
}

.csqa-sidepanel-header {
    font: 24px equipextralight;
    text-align: center;
}

.csqa-platform-sidepanel-textelement {
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background-color: #fff;
    margin: 0px;
    min-height: 350px;
    border-radius: 5px;
}

.btn-primary.csqa-form-button {
    width: 272px;
    height: 48px;
    margin-top: 15px;
    background-color: #1998d5;
    color: #fff;
    text-align: center;
    font-family: equipmedium;
    font-size: 16px;
    border-radius: 25px;
    display: block;

}

.csqa-form-button:hover {
    background-color: #AFD8F1;
    border: 0px;
}

.select-search {
    width: 100%;
    position: relative;
    font-family: equipextralight;
    box-sizing: border-box;
}

.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #424242;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    font-family: equiplight;
    font-size: 18px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search__select {
    background: #fff;
    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
    z-index: 20 !important;
}

.select-search__options {
    list-style: none;
}

.select-search__option {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: equipextralight;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.errorResponse {
    font-family: equiplight;
    font-size: 14px;
    color: red;
    padding: 1%;
}

.Beta {
    background-color: #10628A;
    height: 90px;
    width: 100%;
    display: block;
    color: #fff;
    font-family: equiplight;
    padding: 15px;
    padding-bottom: 20px;
    text-align: center;
}

.Gamma {
    background-color: #10628A;
    height: 90px;
    width: 100%;
    display: block;
    color: #fff;
    font-family: equiplight;
    padding: 15px;
    padding-bottom: 20px;
    text-align: center;
}

.Prod {
    display: none;
}

.csqa-dashboard-table td, th {
    font-size: 14px !important;
}

.csqa-dashboard-table table {
    border: 1px solid #f0f0f0;
}

.review-header {
    font-size: 20px;
    color: #1998d5;
    text-align: center;
}

.review-subheader {
    font-size: 18px;
    color: #1998d5;
    text-align: left;
}

.review-label {
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}

.review-value {
    font-size: 14px;
}

.review-details p {
    margin-bottom: 5px;
}

.review-row {
    display: flex;
    margin-bottom: 20px;
}

.review-column {
    flex: 50%;
    padding-left: 5px;
    padding-right: 5px;
}

.muted-text {
    color: #B4B4B4;
    margin-left: 10px;
}

.csqa-dashboard-button {
    border-radius: 5px !important;
}

.reset-button {
    position: absolute;
    top: 85px;
    right: 50px;
    width: 75px;
    border-radius: 5px;
    background-color: #f6f8fa;
    border: 2px #1998d5;
}

.reset-button:hover {
    background-color: #1998d5;
    color: #fff;
}

.open {
    display:block;
}

.closed {
    display: none;
}

.warning-text {
    font-size: 13px;
    color: red;
}

.hidden {
    display: none;
    opacity: 0;
    transition: visibility 2s, opacity 0.5s linear;
}

.visible {
    display: block;
    opacity: 1;
}

.csqa-rte-editor {
    min-height: 250px;
}

.csqa-rte-toolbar {
    z-index: 1 !important;
}

/* Interactive Button */
.csqa-header .has-focus {
    animation: none !important;
}

.csqa-platform-category-dropdown:hover {
    animation: none !important;
}

.csqa-header .is-active {
    animation: .4s jump ease infinite alternate;
    z-index: 8;
}

@keyframes jump {
    0% {
        transform: scale(1);
        box-shadow: 0 1px 2px rgba(0,0,0,.15);
    }
    100% {
        transform: scale(1.05);
        box-shadow: 0 4px 20px rgba(0,0,0,.1);
    }
}
