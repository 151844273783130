/* HEADER */
.coaching-corner-option {
    width: auto;
    bottom: 0px;
    text-align: center;
    align-self: flex-end;
    justify-content: end;
    color: #fff;
    background-color: #a4b3c3 !important;
    border-bottom: 0px solid #A4B3C3 !important;
    font-family: equipMedium;
    font-size: 16px;
    padding: 2px;
}

.coaching-role-dropdown {
    width: auto;
    align-self: flex-end;
    justify-content: end;
    padding: 10px;
}

@media (max-width: 975px) {
    .coaching-role-dropdown {
        display: none;
    }
}

.coaching-corner-option .ant-menu-submenu,
.ant-menu-submenu-open,
.ant-menu-submenu-title,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-horizontal,
.ant-menu-item-selected,
.ant-menu-overflow-item {
    color: #fff !important;
}

.coaching-options-submenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1890ff !important;
}

.coaching-corner-icon.anticon {
    font-size: 24px;
    align-self: flex-end;
}

/* FORM BODY */
.coaching-corner-form {
    padding: 20px;
    background-color: #f6f8fa;
}

.coaching-rte-toolbar {
    display: none;
}

.coaching-field {
    padding-left: 5px;
    margin: 5px 0px;
    padding-right: 5px;
}

.coaching-field .ant-form-item {
    margin: 0px;
}

.coaching-notes {
    padding-left: 5px;
    padding-right: 45px;
}

.coaching-formid {
    margin-top: 15px;
}

.coaching-input {
    height: 42.5px;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    font-size: 18px !important;
    color: rgb(66, 66, 66) !important;
}

.coaching-attachment input {
    background-color: #fff;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    color: rgb(66, 66, 66) !important;
    margin: auto;
    width: 100%;
    align-self: center;
}

.coaching-disclaimer {
    font-size: 11px;
    color: rgb(66, 66, 66) !important;
    margin-top: 3px;
}

.coaching-disclaimer span {
    margin-left: 15px;
    border-left: 1px solid #b5b5b5;
    padding-left: 15px;
}

.coaching-date {
    width: 100%;
}

.coaching-corner-form .form-group {
    padding-right: 10px;
}

.coaching-corner-subtitle {
    font-family: equipMedium;
    font-size: 16px;
    color: #69BCE3;
    padding: 5px;
}

.notes-spacer {
    margin-top: 10px;
}

.form-column.ant-col {
    display: flex;
    flex-wrap: wrap;
    border-right: 3px solid #fff;
    margin-bottom: 50px;

}

.icon-column.ant-col {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

.coaching-details {
    text-align: center;
}

.coaching-details-option {
    margin: auto;
    margin-bottom: 15px;
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;

}

.coaching-details .select-search__group-header {
    color: #1998d5;
    padding: 2px;
}

.coaching-corner-form .select-search__option:hover {
    background-color: #f6f8fa;
    color: #1998d5;
    font-family: equipMedium;
}

.coaching-corner-form ul {
    margin-left: 0;
}

.coaching-form-submit {
    margin-top: 110px;
}

.coaching-corner-button.ant-btn {
    width: 100%;
    height: 48px;
    background-color: #1998d5;
    color: #fff;
    text-align: center;
    font-family: equipmedium;
    font-size: 16px;
    border-radius: 25px;
    display: block;
}

.coaching-corner-error {
    text-align: left;
    font-family: equiplight;
    font-size: 14px;
    color: red;
    padding: 1%;
}

.coaching-corner-warning {
    color: red;
    padding: 1%;
}

/* Dashboard */

.coaching-dashboard-wrapper {
    height: 100vh;
    background-color: #f9f9f9;
    padding: 1% 2%;
}

.coaching-description {
    display: list-item;
    position: relative;
}

.coaching-dashboard-table td,
th {
    font-size: 14px !important;
}

.coaching-dashboard-table table {
    border: 1px solid #f0f0f0;
}

.coaching-dashboard-column {
    min-width: 125px;
}

.coaching-dashboard-column-large {
    min-width: 200px;
}

.coaching-dashboard-date {
    width: 250px;
    height: 42.5px;
    border-radius: 5px !important;
    margin-left: 10px !important;
}

.coaching-dashboard-button {
    border-radius: 5px !important;
}

.csv-export {
    margin-right: 10px;
}

a.csv-export {
    text-decoration: none !important;
}

.coaching-row {
    display: flex;
    margin-bottom: 20px;
}

.coaching-column {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
}

.coaching-label {
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
}

.coaching-value {
    font-size: 14px;
}

.coaching-value a {
    color: #0089c7 !important;
}

.coaching-attachment-button {
    position: absolute !important;
    bottom: 25px;
    right: 75px;
}

.coaching-test-dropdown .select-search .select-search__select {
    z-index: 250 !important;
}

.ant-btn.coaching-reprocess-button {
    position: absolute;
    right: 225px;
    top: 12.5px;
    width: 150px;
}

.ant-btn.coaching-deliver-button {
    position: absolute;
    right: 50px;
    top: 12.5px;
    width: 150px;
}

.ant-btn.coaching-download-button {
    position: absolute;
    right: 50px;
    top: 12.5px;
    width: 235px;
}

.ant-btn.coaching-delete-button {
    position: absolute;
    right: 50px;
    top: 12.5px;
    width: 150px;
    background-color: #52c41a;
    border: #52c41a;
}

.ant-btn.coaching-approve-button {
    position: absolute;
    right: 400px;
    top: 12.5px;
    width: 150px;
    background-color: #52c41a;
    border: #52c41a;
}

.ant-btn.coaching-approve-button:hover {
    background-color: #73d13d;
    border: #73d13d;
}

.ant-btn.coaching-approve-button:active {
    background-color: #389e0d;
    border: #389e0d;
}

.ant-btn.coaching-approve-button:disabled {
    background-color: #f6ffed;
    border: #b7eb8f;
}

.ant-btn.coaching-approve-button:focus {
    background-color: #73d13d;
    border: #73d13d;
}

.additionalNotesPopover {
    max-width: 750px;
}

.additionalNotesWrapper .ant-popover-inner {
    border: 2px solid red;
}

.additionalNotesWrapper .ant-popover-title {
    font-weight: 700;
}

.additionalNotesPopoverButton {
    margin-left: 175px;
}

/* Indicators */
.coaching-indicator {
    font-size: 13px;
    font-family: equipmedium;
    width: 110px;
    border-radius: 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coaching-indicator .anticon {
    padding-right: 4px;
}

.rework {
    border: 1px solid red;
    color: red;
}

.pending {
    border: 1px solid orange;
    color: orange;
}

.ready {
    border: 1px solid #1998d5;
    color: #1998d5;
}

.delivered {
    border: 1px solid #40A142;
    color: #40A142;
}

.deleted {
    border: 1px solid rgba(66, 66, 66, 0.75);
    color: rgba(66, 66, 66, 0.75);
}


/* Coaching Modal */
.ant-modal.coaching-modal {
    width: 65% !important;
}

.coaching-preview {
    background-color: #f6f8fa;
    border-radius: 5px;
    padding: 2%;
    max-height: 250px;
    overflow-y: scroll;
    margin-bottom: 20px;
}

.coaching-subheader {
    margin-top: 25px;
    font-family: equipmedium;
}
