/* Body */
.body {
    margin-top: 0px;
    background-color: #fff;
}

.text-editor {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    font-size: 14px;
    height: 250px;
    overflow-y: scroll;
}
 
.text-editor .ProseMirror {
    height: 248px;
    padding: 10px;
    overflow: scroll;
}
 
.text-editor:active {
    height: 250px;
    overflow-y: scroll;
}
 
.text-editor:focus {
    height: 250px;
    overflow-y: scroll;
}
 
.text-editor:focus-within {
    height: 250px;
    overflow-y: scroll;
}
 
.text-editor:target {
    height: 250px;
    overflow-y: scroll;
}